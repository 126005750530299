(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/svenskaspel-backbone-history/assets/javascripts/history.js') >= 0) return;  svs.modules.push('/components/app/svenskaspel-backbone-history/assets/javascripts/history.js');

(function(Backbone) {
  'use strict';

  var History = Backbone.History.extend({
    start: function() {
      if (
        arguments.length > 0 &&
        arguments[0].pushState &&
        window.location.hash
      ) {
        window.location.hash = '';
      }
      if (!Modernizr.history) {
        if (
          arguments.length > 0 &&
          !Object.prototype.hasOwnProperty.call(arguments[0], 'hashChange') &&
          arguments[0].pushState
        ) {
          var args = arguments;
          args[0].hashChange = false;
          return Backbone.History.prototype.start.apply(this, args);
        }
        return Backbone.History.prototype.start.apply(this, arguments);
      }
      return Backbone.History.prototype.start.apply(this, arguments);
    }
  });
  Backbone.history = (function() {
    return new History();
  })(Backbone.history);
})(window.Backbone);


 })(window);